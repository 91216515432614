import React, { useState, useEffect } from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "../util/CheckoutForm";
import Discounts from "./Discounts";
import Why from "./Why";
import Footer from "./Footer"
import styled from "styled-components";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const stripePromise = loadStripe('pk_test_51NaA67FyBRy44fLR0WCZF393F3OKj6AS5IQbYTu4kbmFyULthUjSLeMjMIPDjGS0nr4grf7ZwGLinuJNXfgE3ugu00jfhWXfkk');

const Home = () => {
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
      <>
        <Wrapper> 
          <Container className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
            <Row className="justify-content-md-center">
              <Col xxs xs lg="9"> 
                <h2 style={{marginTop:'25vh', marginBottom:'2vh', fontWeight:'normal'}}>Coming Soon!</h2>
                <div style={{textAlign:"left"}}>MAARP is an interest group for younger American generations. MAARP empowers members by providing valuable information, discounts and resources that preserve and build wealth for Millennials and Gen-Z. More than 70 million Americans are aged 18 - 41, and people want their money, health, and happiness to last as long as they do. We are a wise friend and fierce defender, focusing on the priorities of younger Americans.</div>
                <div style={{marginTop:'2.5vh'}}>Support early access today for only <a style={{color:'white',fontWeight:'normal'}} href="https://buy.stripe.com/00g3eTdiad9rbuwaEE" target='blank'>$12 for your first year</a></div>
                {clientSecret && (
                  <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm />
                  </Elements>
                )}
              </Col>
            </Row>
            <Discounts/> 
          </Container>

        </Wrapper>

        <Container style={{backgroundColor:'white'}}>
        <Row>
            <Col> 
              <Why/>
            </Col> 
        </Row>
        </Container>
        <Footer/>
      </>
      );
}

const Wrapper = styled.section`
  background-color: black;
  div {
    font-size:18px;
  }
`;



export default Home