import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
} from "reactstrap";

function NavbarMain() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [buttonColor, setButtonColor] = React.useState("jn-btn");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-transparent");
        setButtonColor("jn-btn-black")
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/index"
            target="_blank"
            tag={Link}
          >
          <Link className="navbar-brand" style={{fontFamily:"myFirstFont", fontSize:"36px"}} to="/">MAARP</Link>
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/maarp.online/"
                target="_blank"
                title="Follow us on Instagram"
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
            </NavItem>
            <NavItem>
                <Link className="nav-link" to="/health">Health</Link>
            </NavItem>
            <NavItem>
                <Link className="nav-link" to="/wealth-exchange">Wealth</Link>
            </NavItem>
            <NavItem>
                <Link className="nav-link" to="/wealth-exchange">Affordable Housing</Link>
            </NavItem>
            <NavItem>
                <Link className="nav-link" to="/legal-resources">Economic Justice</Link>
            </NavItem>
            <NavItem>
                <Link className="nav-link" to="/direct-action">Direct Action</Link>
            </NavItem>
            <NavItem>
                <Link className="nav-link" to="/empowerment">Empowerment</Link>
            </NavItem>
            <NavItem>
              <Button
                className={classnames(`btn-round bt-border ${buttonColor}`)}
                href="https://buy.stripe.com/00g3eTdiad9rbuwaEE"
                color="neutral"
                target="_blank"
              >
                 Join
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}


export default NavbarMain;
