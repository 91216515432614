import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import styled from "styled-components";
import {loadStripe} from '@stripe/stripe-js';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./views/Home";
import Index from "./views/Index.js"
import Register from "./views/register"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavbarMain from "components/Navbars/NavbarMain.js";
const stripePromise = loadStripe('pk_test_51NaA67FyBRy44fLR0WCZF393F3OKj6AS5IQbYTu4kbmFyULthUjSLeMjMIPDjGS0nr4grf7ZwGLinuJNXfgE3ugu00jfhWXfkk');

const DirectAction = () => <div>Direct Action</div>;
const Health = () => <div>Health</div>;
const WealthExchange = () => <div>Wealth Exchange</div>;
const HomeBuying = () => <div>Home Buying</div>;
const StudentDebtRelief = () => <div>Student Debt Relief</div>;
const WorkAndJobs = () => <div>Work and Jobs</div>;
const Media = () => <div>Media</div>;
const LegalResources = () => <div>Legal Resources</div>;
const Empowerment = () => <div>Empowerment</div>;
const Terms = () => <div>Terms of Service</div>;
const Pk = () => <div>pk react</div>;


const Navbar3 = () => {
  return(
  <NavbarMain/>
  )
}

function App() {
  return (
    <Router>
      <Navbar3/>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/direct-action" element={<DirectAction />} />
          <Route path="/health" element={<Health />} />
          <Route path="/wealth-exchange" element={<WealthExchange />} />
          <Route path="/home-buying" element={<HomeBuying />} />
          <Route path="/student-debt-relief" element={<StudentDebtRelief />} />
          <Route path="/work-and-jobs" element={<WorkAndJobs />} />
          <Route path="/media" element={<Media />} />
          <Route path="/legal-resources" element={<LegalResources />} />
          <Route path="/empowerment" element={<Empowerment />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/register" element={<Register />} />
          <Route path="/pk" element={<Index />} />
        </Routes>
      </div>
    </Router>
  );
}

const Button = styled.button`
background-color: white;
color: black;
border-radius: 12px;
padding: 8px 28px;
margin: 0px 0px 0px 15px;
border: 1px solid white;
a { 
  color: black;
  text-decoration:none
}
`;


export default App;